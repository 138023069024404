/**
 * Foursource page
 * https://foursource.com/scheduler
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/scheduler";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import TitleSeparator from "../components/titleSeparator";

class schedulerDenyPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="scheduler" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="pb-75 pt-50 text-center">
            <TitleSeparator info={this.text.deny}></TitleSeparator>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(schedulerDenyPage);
